function validarCPF(cpf) {
	cpf = cpf.replace(/[^\d]+/g,'');
	if(cpf == '') return false;
	// Elimina CPFs invalidos conhecidos
	if (cpf.length != 11 ||
		cpf == "00000000000" ||
		cpf == "11111111111" ||
		cpf == "22222222222" ||
		cpf == "33333333333" ||
		cpf == "44444444444" ||
		cpf == "55555555555" ||
		cpf == "66666666666" ||
		cpf == "77777777777" ||
		cpf == "88888888888" ||
		cpf == "99999999999")
			return false;
	// Valida 1o digito
	add = 0;
	for (i=0; i < 9; i ++)
		add += parseInt(cpf.charAt(i)) * (10 - i);
		rev = 11 - (add % 11);
		if (rev == 10 || rev == 11)
			rev = 0;
		if (rev != parseInt(cpf.charAt(9)))
			return false;
	// Valida 2o digito
	add = 0;
	for (i = 0; i < 10; i ++)
		add += parseInt(cpf.charAt(i)) * (11 - i);
	rev = 11 - (add % 11);
	if (rev == 10 || rev == 11)
		rev = 0;
	if (rev != parseInt(cpf.charAt(10)))
		return false;
	return true;
}
// if(document.getElementById('celular')){
//     document.getElementById('celular').addEventListener('input', function (e) {
//         var x = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
//         e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
//     });
// }


// if(document.getElementById('fone')){
//     document.getElementById('fone').addEventListener('input', function (e) {
//         var x = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
//         e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
//     });
// }

// cpf
if(document.getElementById("celular")){
    document.addEventListener('keydown', function(event) { //pega o evento de precionar uma tecla
        if(event.keyCode != 46 && event.keyCode != 8){//verifica se a tecla precionada nao e um backspace e delete
          var i = document.getElementById("celular").value.length; //aqui pega o tamanho do input
          if (i === 8 ) //aqui faz a divisoes colocando um ponto no terceiro e setimo indice
            document.getElementById("celular").value = document.getElementById("celular").value + "-";
          else if (i === 2) //aqui faz a divisao colocando o tracinho no decimo primeiro indice
            document.getElementById("celular").value = document.getElementById("celular").value + " ";
        }
    });

}

// cpf
if(document.getElementById("cpf")){
    document.addEventListener('keydown', function(event) { //pega o evento de precionar uma tecla
        if(event.keyCode != 46 && event.keyCode != 8){//verifica se a tecla precionada nao e um backspace e delete
          var i = document.getElementById("cpf").value.length; //aqui pega o tamanho do input
          if (i === 3 || i === 7) //aqui faz a divisoes colocando um ponto no terceiro e setimo indice
            document.getElementById("cpf").value = document.getElementById("cpf").value + ".";
          else if (i === 11) //aqui faz a divisao colocando o tracinho no decimo primeiro indice
            document.getElementById("cpf").value = document.getElementById("cpf").value + "-";
        }
    });

}
// data
if(document.getElementById("data")){
    document.addEventListener('keydown', function(event) { //pega o evento de precionar uma tecla
        if(event.keyCode != 46 && event.keyCode != 8){//verifica se a tecla precionada nao e um backspace e delete
            var i = document.getElementById("data").value.length; //aqui pega o tamanho do input
            if (i === 2 || i === 5){ //aqui faz a divisoes colocando um ponto no terceiro e setimo indice
                document.getElementById("data").value = document.getElementById("data").value + "/";
            }
        }
    });
}
window.copyInput = function(el, id){
    var copyText = document.getElementById(id);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    el.innerHTML = 'Código Copiado!';
    el.style.background = '#007bff';
    el.style.color = "#fff";

}
