if (document.getElementById('blog-comments')) {
    const url = APP_URL;
    app.blogResponse = function(question) {
        let comment_response = document.getElementById('blog-response' + question)
        if (authUserId) {
            console.log(comment_response);
            axios.post(url + '/blog/registrar-resposta', {
                    author_id: authUserId,
                    question_id: question,
                    body: comment_response.value
                })
                .then(function(response) {
                    console.log(response);
                    document.getElementById('collapseQuestion' + question).classList.remove("show");
                    comment_response.value = '';
                    app.successAlert();
                })
                .catch(function(error) {
                    console.log(error);
                });
        } else {
            app.logoutAlert();
        }
    }
    app.blogComment = function(blog) {
        let comment = document.getElementById('new_comment')
        if (authUserId) {
            axios.post(url + '/blog/registrar-comentario', {
                    author_id: authUserId,
                    blog_id: blog,
                    body: comment.value
                })
                .then(function(response) {
                    comment.value = '';
                    console.log(response);
                    app.successAlert();
                })
                .catch(function(error) {
                    console.log(error);
                });
        } else {
            app.logoutAlert();
        }
    }
    app.logoutAlert = function() {
        Swal.fire({
            icon: 'info',
            title: 'É necessario entrar na sua conta!',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonColor: `#1BC042`,
            confirmButtonText: `Entrar`,
            cancelButtonText: `Cancelar`,
            denyButtonText: `Cadastrar`,
            denyButtonColor: "#007bff",
            denyButtonClass: "w-25",
            confirmButtonClass: "w-25",
            cancelButtonClass: "w-50 mt-3",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                window.location.replace(APP_URL + '/login');
            } else if (result.isDenied) {
                window.location.replace(APP_URL + '/register');
            }
        })
    }
    app.successAlert = function() {
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Obrigado pelo seu comentário',
            showConfirmButton: true,
        })
    }

}

if(document.getElementById('blogs-mobile')){
    const URL = APP_URL;
    registerNews = function(form){
        axios.post('/news-blogs', {
            email: form.getElementsByClassName("email")[0].value,
          })
          .then(function (response) {
            console.log(response.data);
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Email cadastrado!',
                text: 'Fique de olho à sua caixa de e-mail para receber conteúdos exclusivos do universo da estética.',
                showConfirmButton: true,
                customClass: {
                    title: 'font-primary',
                    text: 'font-secondary',
                    confirmButton: 'w-25',
                }
            });
            form.reset();
          })
          .catch(function (error) {
            console.log(error);
          });

    }

}
