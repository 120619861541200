window.progressFrete = function progressFrete(old_price, new_price) {

    var elem = document.querySelector(".my-bar");
    var description = document.querySelector(".bar-content");
    var new_price_percent = new_price / 500 * 100
    var old_price_percent = old_price / 500 * 100
    old_price_percent = Math.round(old_price_percent)
    var rest = 500 - new_price
    if (new_price_percent >= 100) {
        var content = 'Parabéns! Você ganhou Frete Grátis';
        description.innerText = content;
        new_price_percent = 100;
        elem.style.width = new_price_percent + "%";
    } else if (new_price == 0) {
        description.innerText = 'Ganhe frete grátis - Faltam R$500,00';
    } else {
        var content = 'Ganhe frete grátis - Faltam R$' + rest.toFixed(2);
        description.innerText = content;
    }

    // dinamic bar
    if (new_price_percent < 100) {
        var elem = document.querySelector(".my-bar");
        var width = old_price_percent;
        if (old_price_percent < new_price_percent) {
            var id = setInterval(frame, 20);

            function frame() {
                if (width >= new_price_percent) {
                    clearInterval(id);
                } else {
                    width++;
                    elem.style.width = width + "%";
                }
            }
        } else {
            var id = setInterval(frame, 20);

            function frame() {
                if (width <= new_price_percent) {
                    clearInterval(id);
                } else {
                    width--;
                    elem.style.width = width + "%";
                }
            }
        }
    }

}

window.getLastSubTotal = function() {
    antes = document.getElementById("subtotal__price").innerText
    limpo = antes.replace('R$ ', "");
    return parseInt(limpo);
}
