if (document.getElementById("btn-home")) {
    document.getElementById("btn-home").addEventListener("click", function() {
        document.getElementById("register-perfil").classList.add("d-none");
        document.getElementById("register-dados").classList.remove("d-none");
    });
}
if (document.getElementById("btn-prof")) {
    document.getElementById("btn-prof").addEventListener("click", function() {
        document.getElementById("register-perfil").classList.add("d-none");
        document.getElementById("register-dados").classList.remove("d-none");
    });
}
let btn_element = document.querySelector(".btn-finalizar");
let success_feed =  document.getElementById("success-feed");
let error_feed =  document.getElementById("error-feed");
app.uploadFile = function(file) {
        success_feed.classList.add("d-none");
        error_feed.classList.add("d-none");
        let data = new FormData();
        data.append('file', file);
        data.append('user_id', authUserId);
        window.axios({
                method: 'POST',
                url: 'https://api.dermalogic.com.br/api/file-upload',
                data: data,
                headers: { 'Content-Type': 'multipart/form-data' },
                maxContentLength: 3000000,
                maxBodyLength: 3000000,
            })
            .then(res => {
                var status = res.data;
                if (status.errors) {
                    document.getElementById('file').style.boxShadow = "0 0 0 0.25rem #f7c2c1";
                    error_feed.classList.remove("d-none");
                }
                if (status.success) {
                    btn_element.removeAttribute('disabled');
                    btn_element.classList.remove("bg-secondary-color-1");
                    btn_element.classList.add("btn-success");
                    document.getElementById("check-term").checked = true;
                    document.getElementById('file').style.boxShadow = "0 0 0 0.25rem rgb(199 234 70 / 25%)";
                    success_feed.classList.remove("d-none");
                }
            })
            .catch(err => {
                document.getElementById('file').style.boxShadow = "0 0 0 0.25rem #f7c2c1";
                error_feed.classList.remove("d-none");
            })
    }
    // chamar alert de verificacao de codigo de email
app.finalizarProfissional = function($regra) {
    if ($regra == 1) {
        // chamar axios post e atualizar como liberado
        window.location.replace(APP_URL);
    } else {
        app.modalCode();
    }
}

app.modalCode = function() {
        user_mail = document.getElementById('user_email').value;
        Swal.fire({
            allowOutsideClick: false,
            title: 'Confirmação',
            html: ` <p class="secondary-color-1">Digite o código enviado para <strong>` + user_mail + `</strong> no campo abaixo para finalizar o processo.</p>
                    <input type="text" id="codigo" class="swal2-input text-center" placeholder="Digite o código" maxlength="6" required>`,
            confirmButtonText: 'Verificar código',
            confirmButtonColor: "#1BC042",
            confirmButtonClass: "w-75 mb-4 py-3 text-uppercase fw-bold",
            showConfirmButton: true,
            showDenyButton: true,
            denyButtonText: 'Receber código novamente',
            denyButtonColor: "#696F73",
            denyButtonClass: "w-75",
            footer: `<a href="/central-de-atendimento" aria-label="Acesse a página de central de atendimento">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                        </svg>
                        Preciso de ajuda
                    </a>`,
            focusConfirm: false,
            preConfirm: () => {
                const codigo = Swal.getPopup().querySelector('#codigo').value
                if (!codigo) {
                    // validando o input vazio
                    Swal.showValidationMessage(`Digite o código enviado ao seu email`)
                } else {
                    // chamar axios validar codigos
                    var URL = APP_URL + '/users/validate-code/code/' + codigo;
                    window.axios({
                            method: 'get',
                            url: URL,
                        })
                        .then((result) => {
                            var data = result.data;
                            if (data.error) {
                                app.modalCode();
                                Swal.showValidationMessage(data.error);
                            }
                            if (data.success) {
                                Swal.fire({
                                    position: 'center',
                                    icon: 'success',
                                    title: 'Seja bem vindo!',
                                    showConfirmButton: false,
                                    timer: 2500
                                })
                                window.location.replace(APP_URL);
                            }
                        })
                        .catch((error) => {
                            Swal.showValidationMessage(`Confirme o código e tente novamente`);
                        })
                    return false;
                }
            }
        }).then((result) => {
            //efeito de load
            if (result.isDenied) {
                let timerInterval
                Swal.fire({
                    html: 'Aguarde enquanto enviamos o seu email',
                    timer: 5000,
                    timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading()
                        timerInterval = setInterval(() => {
                            const content = Swal.getHtmlContainer()
                            if (content) {
                                const b = content.querySelector('b')
                                if (b) {
                                    b.textContent = Swal.getTimerLeft()
                                }
                            }
                        }, 100)
                    },
                    willClose: () => {
                        clearInterval(timerInterval)
                    }
                }).then((result) => {
                    /* Read more about handling dismissals below */
                    if (result.dismiss === Swal.DismissReason.timer) {
                        app.modalCode();
                    }
                });
                // reenviar código para o email
                var URL = APP_URL + '/users/resend-code'
                window.axios({
                        method: 'get',
                        url: URL,
                    })
                    .then((result) => {
                        app.modalCode();
                        Swal.showValidationMessage(`Digite o novo código enviado ao seu email `);
                    })
                    .catch((error) => {
                        app.modalCode();
                        Swal.showValidationMessage(`Digite o novo código enviado ao seu email `);
                    });
            } else {
                app.modalCode();
            }
        });
    }
    // abrir alert de verificacao de email
if (document.getElementById('page-code-verify')) {
    document.addEventListener("DOMContentLoaded", function() {
        app.modalCode();
    });
}
// regra para spiner load button
if (document.getElementById('form-register')) {
    document.getElementById('form-register').addEventListener('submit', function() {
        document.getElementById("register-submit").classList.add("d-none");
        document.getElementById("btn-load").classList.remove("d-none");
    });
}

if (typeof(pendingCertificate) !== 'undefined' && pendingCertificate) {
    console.log(window.location.pathname)
    if ((window.location.pathname != '/finalizar-cadastro-profissional') && (window.location.pathname != '/dashboard/atendimento')) {
        app.sweetCertificate = function() {
            Swal.fire({
                allowOutsideClick: false,
                showConfirmButton: false,
                icon: 'error',
                title: 'Oops...',
                text: `Sua conta PROFISSIONAL está bloqueada devido a um problema com seu certificado`,
                footer: `<a href="/finalizar-cadastro-profissional">Reenviar comprovante</a>`
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire('Saved!', '', 'success')
                } else {
                    app.sweetCertificate();
                }
            })
        }
        app.sweetCertificate();
    }
}

app.seePassword =  function (input,ele,none) {
    let x = document.getElementById(input);
    let remover = document.getElementById(none);
    if (x.type === "password") {
        ele.classList.add('d-none')
        remover.classList.remove('d-none')
        x.type = "text";
    } else {
        ele.classList.add('d-none')
        remover.classList.remove('d-none')
        x.type = "password";
    }
}
