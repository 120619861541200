if (document.getElementById('cart')) {
    window.addEventListener('load', function() {
        $('#subtotal__price').html('R$ ' + 0.00);
        app.verifyOrder();
        app.resizeCart()
    }, false);

    app.verifyOrder = function() {
        var pedido = JSON.parse(localStorage.getItem('pedido'));
        // verify pedido exists
        if (pedido == null || pedido == 0) {
            if (authUserId) {
                // call function to get last pedido
                app.searchPedido();
            } else {
                localStorage.setItem('pedido', JSON.stringify(0));
                document.getElementById('id_pedido').value = 0;
                app.getCart()
            }
        } else {
            if (authUserId) {
                app.searchPedido();
            } else {
                app.getCart();
            }
        }
    }
    app.searchPedido = function() {
        var pedido = JSON.parse(localStorage.getItem('pedido'));
        var URL = APP_URL + '/cart/search-last/'+pedido;
        window
            .axios
            .post(URL)
            .then(function(response) {
                //handle success
                localStorage.setItem('pedido', JSON.stringify(response.data));
                document.getElementById('id_pedido').value = response.data
                app.getCart()
            })
            .catch(function(response) {
                //handle error
                // console.log(response);
            });
    }
    app.addProduct = function(id, qtde) {
        if ($('.cart__items__item[data-id="' + id + '"][data-sale="sale"]').length == 0) {

            app.searchProduct(id, qtde, "add");
        }
        app.openCart();
    }
    app.searchProduct = function(id, qtde, action, element = null) {

        var pedido_item_id = element ?  element.dataset.pedidoItemId : 0
        var pedido = JSON.parse(localStorage.getItem('pedido'));
        var URL = APP_URL + '/cart/search-product';
        window
            .axios
            .post(URL, {
                pedido_id: pedido,
                product_id: id,
                quantidade: qtde,
                action: action,
                pedido_item_id: pedido_item_id
            })
            .then(function(response) {
                //handle success
                localStorage.setItem('pedido', JSON.stringify(response.data['pedido_id']));
                document.getElementById('id_pedido').value = response.data['pedido_id'];
                var attributes = response.data;
                // NÃO POSSUI QTDE SUFICIENTE
                if (attributes.status == 1) {
                    window.Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: "Produto possui apenas: " + (qtde - 1) + " em estoque."
                    })
                }
                // NÃO POSSUI ESTOQUE
                if (attributes.status == 0) {
                    window.Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: "Produto não possui estoque."
                    })
                }
                // ADD PRODUCT
                if (attributes.status == 'added' || attributes.status == 'updated') {
                    app.tagAddProduct(attributes.product_id, attributes.product_sku, attributes.product_nome, attributes.product_quantidade, attributes.product_preco);
                }
                // REMOVE PRODUCT
                if (attributes.status == 'deleted') {
                    app.tagRemoveProduct(attributes.product_id, attributes.product_sku, attributes.product_nome, attributes.product_quantidade, attributes.product_preco);
                }

                app.getCart();
            })
            .catch(function(response) {
                //handle error
                console.log(response);
            });
    }
    app.getCart = function() {
        var pedido = JSON.parse(localStorage.getItem('pedido'));
        var URL = APP_URL + '/cart/draw';
        window
            .axios
            .post(URL, {
                pedido_id: pedido,
            })
            .then(function(response) {
                //handle success
                var cart = response.data;
                // SE O PEDIDO NAO FOI ENCONTRADO E RETORNOU UM NOVO
                if (cart.status == 'new_pedido') {
                    localStorage.setItem('pedido', JSON.stringify(cart.pedido_id))
                }
                document.getElementById('id_pedido').value = cart.pedido_id

                app.drawCart(cart.pedido);
                if (cart.message) {
                    // ALERT ERROR ON ESTOQUE
                    window.Swal.fire({
                        title: 'Item removido do carrinho',
                        html: cart.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    });
                }

            })
            .catch(function(response) {
                //handle error
                console.log(response.data);
            });
    }
    app.deleteProd = function(id, remove) {
        if (undefined != id && id > 0) {
            if (remove == true) {
                app.searchProduct(id, 0, "delete");
            } else {
                window.Swal.fire({
                    title: 'Deseja remover o produto do pedido?',
                    customClass: {
                        container: 'swal2-my-z-index'
                      },
                    showCancelButton: true,
                    confirmButtonText: `Sim`,
                    cancelButtonText: `Não`
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        app.searchProduct(id, 0, "delete");
                        window.Swal.fire({
                            icon: 'success',
                            title: 'Excluido com sucesso!',
                            customClass: {
                                container: 'swal2-my-z-index'
                            },
                            confirmButtonText: `Ok`,
                        })
                    }
                })
            }

        }
    }
    app.tagAddProduct = function(id, codigo, nome, quantidade, preco) {
        gtag('event', 'add_to_cart', {
            "items": [{
                "item_id": id,
                "item_name": nome,
                "item_brand": "Tulípia Cosméticos",
                "item_category": "Cosméticos",
                "list_position": 1,
                "quantity": parseInt(quantidade),
                "price": preco
            }]
        });
        fbq('track', 'AddToCart', {
            value: preco,
            currency: 'BRL',
            contents: [{
                id: codigo,
                quantity: parseInt(quantidade)
            }],
            content_type: 'product',
        });
        pintrk('track', 'AddToCart', {
            value: preco,
            order_quantity: parseInt(quantidade),
            currency: 'BRL'
        });
    }
    app.tagRemoveProduct = function(id, codigo, nome, quantidade, preco) {
        gtag('event', 'remove_from_cart', {
            "items": [{
                "id": id,
                "name": nome,
                "brand": "Tulípia Cosméticos",
                "category": "Cosméticos",
                "list_position": 1,
                "quantity": quantidade,
                "price": preco
            }]
        });
    }
    app.resizeCart = function() {
        window.addEventListener('resize', () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });
    }
    app.toggleCart = function() {
        var elem = document.querySelector('#cart');
        elem
            .classList
            .toggle('cart--active');
    }
    app.openCart = function() {
        var elem = document.querySelector('#cart');
        elem
            .classList
            .add('cart--active');
    }
    app.closeCart = function() {
        var elem = document.querySelector('#cart');
        elem
            .classList
            .remove('cart--active');
    }

    app.clickOpenCart = function() {
        app.toggleCart();
    }

    app.updateItem = function(id, action, element) {
        var fieldName = $(element).data('field');
        var parent = $(element).closest('div');
        //SOMA OU SUBTRAI
        if (action == 'sum') {
            var currentVal = parseInt(
                parent.find('input[name=' + fieldName + ']').val(),
                10
            ) + 1;
            app.searchProduct(id, currentVal, "add", element);
        }
        if (action == 'remove') {
            var currentVal = parseInt(
                parent.find('input[name=' + fieldName + ']').val(),
                10
            ) - 1;
            if (currentVal > 0) {
                app.searchProduct(id, currentVal, "remove", element);
            } else {
                app.deleteProd(id, "true");
            }
        }
    }

    function incrementValue(e) {
        // e.preventDefault();
        var fieldName = $(e).data('field');
        var parent = $(e).closest('div');
        var currentVal = parseInt(
            parent.find('input[name=' + fieldName + ']').val(),
            10
        );

        if (!isNaN(currentVal)) {
            parent
                .find('input[name=' + fieldName + ']')
                .val(currentVal + 1);
        } else {
            parent
                .find('input[name=' + fieldName + ']')
                .val(0);
        }
    }

    function decrementValue(e) {
        // e.preventDefault();
        var fieldName = $(e).data('field');
        var parent = $(e).closest('div');
        var currentVal = parseInt(
            parent.find('input[name=' + fieldName + ']').val(),
            10
        );

        if (!isNaN(currentVal) && currentVal > 1) {
            parent
                .find('input[name=' + fieldName + ']')
                .val(currentVal - 1);
        } else {
            parent
                .find('input[name=' + fieldName + ']')
                .val(1);
        }
    }
    app.drawCart = function(cart) {
        var msgItemRemovido = "",
            msg = '',
            wrapper = $('.cart__items '),
            wrapper_subtotal = $('#subtotal__price'),
            qtde_itens = 0,
            qtde_produtos = 0,
            total = 0;
        var old_price = getLastSubTotal();
        wrapper.html('');
        if (undefined == cart || null == cart || cart == '') {
            wrapper.html(
                '<div class="cart__msg">Você não tem itens no carrinho <br><a href="/" onclick=' +
                '"event.preventDefault();app.toggleCart()">Voltar às compras</a></div>'
            );
            // ZERO ALL
            wrapper_subtotal.html('R$ 0.00');
            $('[name="total_produtos"]').val(0.00);
            $('[name="quantidade_produtos"]').val(0);
            $('[name="itens_pedido"]').val(0);
            progressFrete(old_price, 0);
        } else {
            var items = '';
            cart.forEach(function(item, key) {
                qtde_itens = qtde_itens + 1;
                qtde_produtos = qtde_produtos + item.quantidade;
                total = total + (item.quantidade * item.valor_unitario)
                if(item.gift_id){
                    items += '<div data-sale="gift" class="cart__items__item product-' + item.product_id + '" data-id="' + item.product_id + '"><div class="holder">'
                    items += '<div class="product-image text-center p-2"><svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" class="bi bi-gift-fill text-secondary" viewBox="0 0 16 16"><path d="M3 2.5a2.5 2.5 0 0 1 5 0 2.5 2.5 0 0 1 5 0v.006c0 .07 0 .27-.038.494H15a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h2.038A3 3 0 0 1 3 2.506zm1.068.5H7v-.5a1.5 1.5 0 1 0-3 0c0 .085.002.274.045.43zM9 3h2.932l.023-.07c.043-.156.045-.345.045-.43a1.5 1.5 0 0 0-3 0zm6 4v7.5a1.5 1.5 0 0 1-1.5 1.5H9V7zM2.5 16A1.5 1.5 0 0 1 1 14.5V7h6v9z"/></svg></div>'
                }else{
                    items += '<div data-sale="sale" class="cart__items__item product-' + item.product_id + '" data-id="' + item.product_id + '"><div class="holder">'
                    items += '<div class="product-image text-center"><img height="100" width="100" alt="imagem do produto ' + item.product.nome + ' " src="https://d1ekutaqv55e28.cloudfront.net/products/high-quality/100x100/' + item.product.slug.toLowerCase() + '.png" /></div>'
                }

                items += '<div class="product-info"><p class="product-name">' + item.product.nome_principal +
                    '</p> <p>'+ item.product.subtitulo+'</p> <p>'+ item.product.tamanho+'</p></div>';
                items += '</div>';

                if(item.gift_id){
                    items += '<section class="product-qty-price"><span>';
                        items += '<div class="input-group"><input type="button" value="" class="button-minus" d' +
                        'ata-field="quantity" data-pedido-item-id="'+item.id+'" ><input type="number" step="1" max="" value="' + item.quantidade +
                        '" name="quantity" class="quantity-field" readonly><input type="button" value="" class=' +
                        '"button-plus" data-field="quantity" data-pedido-item-id="'+item.id+'"></div>';
                        items += '<span class="product-price">PRESENTE</span></span><span class="f' +'-right product__price__subtotal text-decoration-line-through">R$' + (item.quantidade * item.valor_unitario).toFixed(2) + '</span></section></div>'
                }else{
                    items += '<a href="/" class="button product-remove js-product-remove" onclick="event.preventDefault();app.deleteProd(' +
                    item.product_id + ')"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">' +
                    '<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>' +
                    '<path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>' +
                    '</svg></a>'
                    items += '<section class="product-qty-price"><span>';
                    items += '<div class="input-group"><input type="button" value="-" class="button-minus" d' +
                    'ata-field="quantity" data-pedido-item-id="'+item.id+'" onclick="app.updateItem(' + item.product_id + ',\'remove\', this)"><input type="number" step="1" max="" value="' + item.quantidade +
                    '" name="quantity" class="quantity-field" readonly><input type="button" value="+" class=' +
                    '"button-plus" data-field="quantity" data-pedido-item-id="'+item.id+'" onclick="app.updateItem(' + item.product_id + ',\'sum\', this)"></div>';
                    items += '<span class="product-price">x R$' + item.valor_unitario + '</span></span><span class="f' +'-right product__price__subtotal">R$' + (item.quantidade * item.valor_unitario).toFixed(2) + '</span></section></div>'
                }

            });
            //SUM PRODUCTS TO Cart
            wrapper_subtotal.html('R$ ' + total.toFixed(2))
            document.addEventListener("DOMContentLoaded", progressFrete(old_price, total.toFixed(2)));
            // ITENS AQUI
            wrapper.html(items)
                // console.log("Mensagem: " + msgItemRemovido);
        }
        app.shoppingCartEmpty(total, qtde_produtos)
    }
        // INSERE NUMERO NO ICONE DO CARRINHO
    app.shoppingCartEmpty = function(total, qtde_itens) {
        if (total > 0) {
            $('.icon-carrinho').removeClass('d-none')
            $('.icon-carrinho').html(qtde_itens)
        } else {
            $('.icon-carrinho').addClass('d-none')
        }
    }
}
