var KEYCODE_ENTER = 13;
var KEYCODE_ESC = 27;

var app = window.app || {};

app.validarCPF = function(cpf) {
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf == '') return false;
    // Elimina CPFs invalidos conhecidos
    if (cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999")
        return false;
    // Valida 1o digito
    add = 0;
    for (i = 0; i < 9; i++)
        add += parseInt(cpf.charAt(i)) * (10 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
        rev = 0;
    if (rev != parseInt(cpf.charAt(9)))
        return false;
    // Valida 2o digito
    add = 0;
    for (i = 0; i < 10; i++)
        add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
        rev = 0;
    if (rev != parseInt(cpf.charAt(10)))
        return false;
    return true;
}
app.show = function(elem) {
    elem.classList.add('is-visible');
};

// Hide an element
app.hide = function(elem) {
    elem.classList.remove('is-visible');
};

// Toggle element visibility
app.toggle = function(elem) {
    elem.classList.toggle('is-visible');
};
app.searchCidades = async function(estado) {
    try {
        const res = await window.axios.get(APP_URL + "/encontre-um-representante/get-cidades/" + estado);
        const cidades = res.data;
        app.constructSelectCidades(cidades);
        // return cidades;
    } catch (e) {
        console.error(e);
    }
}
app.constructSelectCidades = function(cidades) {
    //
    var input = '<option value="0"></option>',
        wrapper = $('#cidade');

    cidades.forEach(function(item) {
        input += '<option value="' + item.cidade + '">' + item.cidade + '</option>'
    })
    wrapper.html(input)
}
app.showRepresentantes = function(estado, cidade = null) {
        // $('.representantes__representante--destaque').addClass('d-none');
        $('.representantes__representante--outros').removeClass('d-none');
        if (cidade == null) {
            $('.representantes__representante__item').hide(200);
            $('.representantes__representante__item[data-estado="' + estado + '"]').delay(250).show(200);
        } else {
            $('.representantes__representante__item[data-estado="' + estado + '"]').hide(100);
            $('.representantes__representante__item[data-estado="' + estado + '"][data-cidade="' + cidade + '"]').delay(150).show(200);
        }
    }
    // ROUND NUMBER WITH 2 DIGITS
function roundToTwo(num) {
    return parseFloat(+(Math.round(num + "e+2") + "e-2")).toFixed(2).replace(".", ",");
}

function roundToThree(num) {
    return parseFloat(+(Math.round(num + "e+3") + "e-3")).toFixed(3).replace(".", ",");
}

function parseMyFloat(num) {
    return parseFloat(num.replace(',', '.'));
}
app.roundTwoFloat = function(num) {
    return parseFloat(+(Math.round(num + "e+2") + "e-2")).toFixed(2);
}

app.toggleMobile = function(e) {
    e.classList.toggle('show');
    var elem = document.getElementById('nav-id'),
        Style = window.getComputedStyle(elem),
        left = Style.getPropertyValue('left');
    if (left == '12px') {
        elem.style.left = '-100%';
    } else {
        elem.style.left = '12px';
    }
}
if ($('#estado option:selected').length) {
    var estado = $('#estado').val();
    app.searchCidades(estado);
    // app.showRepresentantes(estado);
    $('.cidade-input').removeClass('d-none');
}
$('#estado').change(function() {
    var estado = $(this).val();
    console.log('changed');
    app.searchCidades(estado);
    app.showRepresentantes(estado);
    $('.cidade-input').removeClass('d-none');
})
$('#cidade').change(function() {
    var cidade = $(this).val(),
        estado = $('#estado').val();
    app.showRepresentantes(estado, cidade);
})

$('.whatsapp-widget').on('click', function() {
    ga('send', {
        hitType: 'event',
        eventCategory: 'WhatsApp',
        eventAction: 'click',
        eventLabel: 'Contato com WhatsAPP'
    });
})
$('.searchbox__input').on({
    click: function() {
        $('.overlay').fadeIn(300);
    },
    blur: function() {
        $('.overlay').fadeOut(300);
    }
})

if (document.getElementById("filtro")) {
    document.getElementById('filtro').addEventListener('change', function() {
        if ($("#filtro option:selected").val() != 0) {
            window.location.href = APP_URL + "/blogs/" + $("#filtro option:selected").val();
        }
    });
}
if (document.getElementById("ativos-select")) {
    document.getElementById('ativos-select').addEventListener('change', function() {
        if ($("#ativos-select option:selected").val() != 0) {
            window.location.href = APP_URL + "/principios-ativos-cosmeticos/letra/" + $("#ativos-select option:selected").val();
        }
    });
}
if (document.getElementById("ativoBusca")) {
    let resp;
    window.axios.get('/busca-ativos')
    .then(function (response) {
        resp = response.data;
    })
    .catch(function (error) {
      console.log(error);
    });

    app.resultQuery = function(){
        document.querySelector('.length').textContent = '';
        if (document.querySelectorAll(".ativo-card")) {
            document.querySelectorAll(".ativo-card").forEach(function(item) {
                item.remove();
            })
        }
        let  list = document.getElementById('list_query')
        let append = '';
        var input = document.getElementById("input-query");
        let term = input.value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        let filtered = resp.filter(ativo => ativo.titulo.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(term));
        document.querySelector('.ativos-by-letter').classList.add('d-none')
        if(term == ''){
            document.querySelector('.ativos-by-letter').classList.remove('d-none')
        }else{
            if(filtered.length > 0){
                filtered.forEach(function(item) {
                    append += `
                                <article class="col-md-6 col-12 p-2 ativo-card">
                                    <a  href="/principios-ativos-cosmeticos/`+item.slug+`">
                                        <div class=" my-shadow border-radius p-4 bg-white h-100 d-flex flex-column justify-content-between">
                                            <h3 class="font-primary primary-color-2 fs-2">`+item.titulo+`</h3>
                                            <p class="font-secondary secondary-color-1 lh-sm fs-5 my-3" style="overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;">`+item.descricao+`</p>
                                            <div  class="text-end">
                                                <div class="btn btn-color-1 text-uppercase text-white w-50 w-lg-75">continuar lendo</div>
                                            </div>
                                        </div>
                                    </a>
                                </article>
                    `
                })
                document.querySelector('.length').textContent = 'Listamos abaixo '+filtered.length+' ativo'+(filtered.length > 1 ? 's' : ' ')+' encontrado'+(filtered.length > 1 ? 's.' : '.');
            }else{
                append = `
                            <div class="col-12 secondary-color-1 ativo-card text-center my-5">
                                <p class="display-8 mb-2">Opss...</p>
                                <p class="display-9">Não encontramos nenhuma ativo.</p>
                            </div>
                        `
            }
            list.insertAdjacentHTML('beforeend', append);
        }
    }
}
